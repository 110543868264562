/*
    Everything to push / pull data from user API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_USER_URL = `${calc_nhub_api()}/api/user/`

//Get all users
export function getAllUsers(token) {
    return axios
        .get(API_USER_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Add a user
export function postUser(token, content) {
    return axios
        .post(
            API_USER_URL,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Get a user by his ID
export function getUser(token, userId) {
    return axios
        .get(API_USER_URL + userId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Edit a user
export function putUser(token, id, content) {

    return axios
        .put(
            API_USER_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Delete a user by his ID
export function deleteUser(token, userId) {
    return axios
        .delete(API_USER_URL + userId, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}


export function getUserMe(token) {
    return axios
        .get(API_USER_URL + 'me', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Edit and Submit a report
export function putUserMe(token, content) {

    return axios
        .put(
            API_USER_URL + 'me',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//submit your register
export function submitUserMe(token) {

    return axios
        .post(
            API_USER_URL + 'me/submit',
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//set User's Status and add a comment in History
export function backToDraftUser(token, id, content) {

    return axios
        .post(
            API_USER_URL + id + '/backtodraft',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//set User's Status and add a comment in History
export function validateUser(token, id) {

    return axios
        .post(
            API_USER_URL + id + '/validate',
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}