import React from 'react';
import { Routes, Route } from 'react-router-dom';

import '../styles/LoginPage.css'
import loginPageImage from '../assets/images/loginPageImage.webp'
import loginPageImageSmall from '../assets/images/loginPageImage-small.webp'

import LoginFrom from '../components/LoginFrom'
import RegisterFrom from '../components/RegisterFrom'
import LoginBanner from '../components/LoginBanner'

import { useProgressiveImage } from '../hooks/useProgressiveImage';

function LoginPage() {
    const imageLoaded = useProgressiveImage(loginPageImage)

    return (
        <div className='login-page'
            style={{
                backgroundColor: 'rgba(77, 99, 109, 0.5)',
                backgroundImage: `url(${loginPageImage})`
            }}
        >
            <div className="bg-fade" style={{
                backgroundImage: `url(${loginPageImageSmall})`,
                opacity: imageLoaded ? 0 : 1
            }}></div>
            <LoginBanner />
            <div className='login-content'>


                <Routes>
                    <Route path="/" element={<LoginFrom />} />
                    <Route path="/register" element={<RegisterFrom />} />

                    <Route path="*" element={<LoginFrom />} />
                </Routes>
            </div>
        </div>
    );
};
export default LoginPage;