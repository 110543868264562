import React from 'react';

import { useNavigate } from 'react-router-dom';

import '../styles/LoginPage.css'

import { useTranslation } from "react-i18next";

import Button from '@mui/material/Button';

import LoginButton from './LoginButton';

function LoginFrom() {

    const { t } = useTranslation();
    let navigate = useNavigate();

    const handleClickJoinUs = () => navigate('/register');

    return (
        // <div className='login-form'>
        <div>
            <div className='login-form-subContainer login-form-subContainer1'>
                <span className='login-form-titles'>{t('login.join-us')}</span>
                <span className='login-form-subtitles'>{t('login.join-us-2')}</span>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "var(--button-background-8)",
                        textTransform: 'none',
                        fontSize: {xs : '16px', sm: '20px'}, 
                        fontWeight: '400', padding: '6px 10px', marginTop:'8px',
                        borderRadius :'15px',
                        ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                    }}
                    onClick={() => { handleClickJoinUs() }}
                >
                    {t('login.button-join')}
                </Button>
            </div>
            <div className='login-form-subContainer login-form-subContainer2'>
                <span className='login-form-titles login-form-titles2'>{t('login.registered')}</span>
                <LoginButton />
            </div>
        </div>
    );
};
export default LoginFrom;