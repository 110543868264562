import React, { useContext } from "react";
import { useParams } from 'react-router-dom';

import '../styles/InfoPages.css'

import { useTranslation } from "react-i18next";

import { Grid, Button, Link, Skeleton } from '@mui/material';

import { RiSearchLine } from 'react-icons/ri';

import { getMyResume, getResume } from '../services/expInfo.service';
import { getMyIdentityProof, getIdentityProof } from "../services/personalInfo.service";
//import { getMyAOA, getAOA } from '../services/companyInfo.service';
import DownloadButton from './DownloadButton';
import { UserInfosContext } from '../context/UserInfosContext';


function RegisterSummary({ personalInfos, expInfos, companyInfos, testInfos, loadingData }) {

    const { t } = useTranslation();
    const dojoURI = process.env.REACT_APP_DOJO_URI
    const testLinkUri = testInfos?.client_id ? dojoURI + '/admin/clients/' + testInfos?.client_id[0] : ''

    let { userId } = useParams();
    const { userInfos } = useContext(UserInfosContext);

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        // sx={{
        //     width: '100% !important',
        //     marginLeft: '0 !important',
        // }}
        >
            <Grid item container columnSpacing={1} xs={12} md={6} alignContent="flex-start">
                <Grid item xs={12}><h4>{t('appBarNav.personalInfo')}</h4></Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.civility')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.civility}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.firstName')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.firstName}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.lastName')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.lastName}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.birthDate')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.birthDate ? new Date(personalInfos?.birthDate).toLocaleDateString() : null}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.nationality')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.nationality}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.phoneNumber')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.phoneNumber}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.address')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.address}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.additionalAddress')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.additionalAddress || <i className="optionalValue"> {t('personalInfoPage.optional')} </i>}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.city')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.city}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.postalCode')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.postalCode}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.country')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : personalInfos?.country}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('personalInfoPage.identity')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData
                        ? <Skeleton width={'100%'} />
                        : personalInfos?.identity_proof
                            ? userInfos.profile === 'Admin'
                                ? <DownloadButton
                                    downloadParameters={userId}
                                    downloadFunction={getIdentityProof}
                                    label={personalInfos?.identity_proof}
                                    fileName='identity_proof.pdf'
                                />
                                : <DownloadButton
                                    downloadParameters={{}}
                                    downloadFunction={getMyIdentityProof}
                                    label={personalInfos?.identity_proof}
                                    fileName={personalInfos?.identity_proof}
                                // fileName='identity_proof.pdf'
                                />
                            : <span><i>Blank</i></span>
                    }
                </Grid>
            </Grid>
            <Grid item container columnSpacing={1} xs={12} md={6} alignContent="flex-start">
                <Grid item xs={12}><h4>{t('appBarNav.expInfo')}</h4></Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('expInfoPage.lastPosition')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : expInfos?.lastPosition}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('expInfoPage.yearsExp')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : expInfos?.yearsExp}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('expInfoPage.diploma')}
                </Grid>

                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : expInfos?.diploma}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('expInfoPage.linkedin')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : expInfos?.linkedin || <i className="optionalValue"> {t('personalInfoPage.optional')} </i>}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('expInfoPage.resume')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData
                        ? <Skeleton width={'100%'} />
                        : expInfos?.resume
                            ? userInfos.profile === 'Admin'
                                ? <DownloadButton
                                    downloadParameters={userId}
                                    downloadFunction={getResume}
                                    label={expInfos?.resume}
                                    fileName='resume.pdf'
                                />
                                : <DownloadButton
                                    downloadParameters={{}}
                                    downloadFunction={getMyResume}
                                    label={expInfos?.resume}
                                    fileName={expInfos?.resume}
                                // fileName='resume.pdf'
                                />
                            : <i className="optionalValue"> {t('personalInfoPage.optional')} </i>
                    }
                </Grid>
            </Grid>
            <Grid item container columnSpacing={1} xs={12} md={6} alignContent="flex-start">
                <Grid item xs={12}><h4>{t('appBarNav.companyInfo')}</h4></Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.identification')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.identification}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.number')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.number}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.address')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.address}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.additionalAddress')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.additionalAddress || <i className="optionalValue"> {t('personalInfoPage.optional')} </i>}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.city')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.city}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.postalCode')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.postalCode}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.country')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.country}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.IBAN')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.finance_iban}
                </Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('companyInfoPage.BIC')}
                </Grid>
                <Grid item xs={6} alignItems="center" display="flex" justify="flex-start" className="summary-items summary-values">
                    {loadingData ? <Skeleton width={'100%'} /> : companyInfos?.finance_bic}
                </Grid>
            </Grid>
            <Grid item container columnSpacing={1} xs={12} md={6} alignContent={"space-between"} justifyContent={"center"}>
                <Grid item xs={12}><h4>{t('appBarNav.test')}</h4></Grid>
                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('testInfoPage.flagAnalyst')}
                </Grid>
                <Grid item xs={6} alignItems="center" justify="flex-start" className="summary-items summary-values"
                    sx={testInfos?.analystTest?.status === "Obtained" ? { color: 'green', fontWeight: 'bold' } : null}
                >
                    {loadingData ? <Skeleton width={'100%'} /> : testInfos?.analystTest?.status}
                </Grid>

                <Grid item xs={6} alignItems="center" sx={{ display: "flex", justifyContent: "flex-end" }} className="summary-items summary-titles">
                    {t('testInfoPage.flagHunter')}
                </Grid>
                <Grid item xs={6} alignItems="center" justify="flex-start" className="summary-items summary-values"
                    sx={testInfos?.hunterTest?.status === "Obtained" ? { color: 'green', fontWeight: 'bold' } : null}
                >
                    {loadingData ? <Skeleton width={'100%'} /> : testInfos?.hunterTest?.status}
                </Grid>
                {userInfos.profile === 'Admin'
                    ? <Link href={testLinkUri} target="_blank" rel="noopener noreferrer" title={t('testInfoPage.button-linkDojo')} aria-label={t('testInfoPage.button-linkDojo')} >
                        <Button
                            variant="contained"
                            endIcon={<RiSearchLine />}
                            sx={{
                                backgroundColor: "var(--button-background-4)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                            }}
                        >
                            {t('button.seeInDojo')}
                        </Button>
                    </Link>
                    : null
                }
            </Grid>
        </Grid>
    );
};
export default RegisterSummary;