import '../../styles/Tables.css'

import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Grid, Button, Skeleton } from '@mui/material';

import { RiArrowRightSLine, RiSendPlane2Line, RiDeleteBin2Line, RiMessage2Line, RiThumbUpLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { getUser, putUser, deleteUser, backToDraftUser, validateUser } from '../../services/user.service';
import { getPersonalInfo } from '../../services/personalInfo.service';
import { getExpInfo } from '../../services/expInfo.service';
import { getCompanyInfo } from '../../services/companyInfo.service';
import { getTestInfo } from '../../services/testInfo.service';
import RegisterSummary from '../../components/RegisterSummary';
import RegisterHistory from '../../components/RegisterHistory';
import DialogComment from '../../components/DialogComment';
import DialogAlert from '../../components/DialogAlert';

import { UserInfosContext } from '../../context/UserInfosContext';

const userProfile = [
  {
    value: 'Admin',
    label: 'Admin',
  },
  {
    value: 'Hunter-Analyst',
    label: 'Hunter-Analyst',
  }
];


function UserSingle() {
  const { t } = useTranslation();

  let { userId } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()

  const [dialOpenDelete, setDialOpenDelete] = useState(false);
  const [dialOpenComment, setDialOpenComment] = useState(false);
  const [dialOpenAccept, setDialOpenAccept] = useState(false);

  const [personalInfos, setPersonalInfos] = useState({});
  const [expInfos, setExpInfos] = useState({});
  const [companyInfos, setCompanyInfos] = useState({});
  const [testInfos, setTestInfos] = useState({});

  // const [isLoading, setIsLoading] = useState(true);
  // const [isLoading2, setIsLoading2] = useState(true);
  // const [isLoading3, setIsLoading3] = useState(true);
  // const [isLoading4, setIsLoading4] = useState(true);
  // const [isLoading5, setIsLoading5] = useState(true);

  const { setSnackbarObj } = useContext(UserInfosContext);


  const handleClickOpenAccept = () => { setDialOpenAccept(true); };
  const handleClickOpenDelete = () => { setDialOpenDelete(true); };
  const handleClickOpenComment = () => { setDialOpenComment(true); };
  const handleClose = () => {
    setDialOpenAccept(false)
    setDialOpenDelete(false)
    setDialOpenComment(false)
  };

  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
    failureReason: failureUser,
    failureCount: failureCountUser,
    isFetching: fetchingUser
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['user', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getUser(token, userId)),
  });
  useEffect(() => {
    if (user) { setUserFormValues(user); }
  }, [user])

  const {
    data: personalInfo,
    isLoading: loadingPersonalInfo,
    error: errorPersonalInfo,
    failureReason: failurePersonalInfo,
    failureCount: failureCountPersonalInfo,
    isFetching: fetchingPersonalInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['personalInfo', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getPersonalInfo(token, userId)),
  });
  useEffect(() => {
    if (personalInfo) { setPersonalInfos(personalInfo); }
  }, [personalInfo])

  const {
    data: expInfo,
    isLoading: loadingExpInfo,
    error: errorExpInfo,
    failureReason: failureExpInfo,
    failureCount: failureCountExpInfo,
    isFetching: fetchingExpInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['expInfo', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getExpInfo(token, userId)),
  });
  useEffect(() => {
    if (expInfo) { setExpInfos(expInfo); }
  }, [expInfo])

  const {
    data: companyInfo,
    isLoading: loadingCompanyInfo,
    error: errorCompanyInfo,
    failureReason: failureCompanyInfo,
    failureCount: failureCountCompanyInfo,
    isFetching: fetchingCompanyInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['companyInfo', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getCompanyInfo(token, userId)),
  })
  useEffect(() => {
    if (companyInfo) { setCompanyInfos(companyInfo); }
  }, [companyInfo])

  const {
    data: testInfo,
    isLoading: loadingTestInfo,
    error: errorTestInfo,
    failureReason: failureTestInfo,
    failureCount: failureCountTestInfo,
    isFetching: fetchingTestInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    queryKey: ['testInfo', { _id: userId }],
    queryFn: () => getAccessTokenSilently().then((token) => getTestInfo(token, userId)),
  })
  useEffect(() => {
    if (testInfo) { setTestInfos(testInfo); }
  }, [testInfo])

  // react state. used for field validation and initial display
  const [userFormValues, setUserFormValues] = useState({
    lastName: '',
    firstName: '',
    email: '',
    profile: '',
    history: '',
  });

  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    setUserFormValues({ ...userFormValues, [name]: value })
  }

  const putUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => putUser(token, userId, userFormValues)),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    putUserMutation.mutate({ userFormValues })
  }


  const validateUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => validateUser(token, userId)),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleAccept = (e) => {
    e.preventDefault()
    validateUserMutation.mutate()
  }

  const deleteUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => deleteUser(token, userId)),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleDelete = (e) => {
    e.preventDefault()
    deleteUserMutation.mutate()
  }

  const backToDraftUserMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!userId, // ensures the query is only executed when these conditions are met.
    mutationFn: (content) => getAccessTokenSilently().then((token) => backToDraftUser(token, userId, content)),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', { _id: userId }], data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['usersList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/users/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('user', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleBackToDraft = (e, content) => {
    e.preventDefault()
    backToDraftUserMutation.mutate(content)
  }



  useEffect(() => {

    const snackbarPersonalInfos = ResponseSnackbarErrorHandler('personalInfo', errorPersonalInfo, failurePersonalInfo, failureCountPersonalInfo)
    if (snackbarPersonalInfos) { setSnackbarObj(snackbarPersonalInfos) }

    const snackbarExpInfos = ResponseSnackbarErrorHandler('expInfo', errorExpInfo, failureExpInfo, failureCountExpInfo)
    if (snackbarExpInfos) { setSnackbarObj(snackbarExpInfos) }

    const snackbarCompanyInfos = ResponseSnackbarErrorHandler('companyInfo', errorCompanyInfo, failureCompanyInfo, failureCountCompanyInfo)
    if (snackbarCompanyInfos) { setSnackbarObj(snackbarCompanyInfos) }

    const snackbarTestInfos = ResponseSnackbarErrorHandler('testInfo', errorTestInfo, failureTestInfo, failureCountTestInfo)
    if (snackbarTestInfos) { setSnackbarObj(snackbarTestInfos) }

    const snackbarUserInfos = ResponseSnackbarErrorHandler('user', errorUser, failureUser, failureCountUser)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

  }, [errorCompanyInfo, errorExpInfo, errorPersonalInfo, errorTestInfo, errorUser, failureCompanyInfo, failureCountCompanyInfo, failureCountExpInfo, failureCountPersonalInfo, failureCountTestInfo, failureCountUser, failureExpInfo, failurePersonalInfo, failureTestInfo, failureUser, setSnackbarObj]);


  // /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  // if ((loadingUser && fetchingUser) ||
  //   (loadingPersonalInfo && fetchingPersonalInfo) ||
  //   (loadingCompanyInfo && fetchingCompanyInfo) ||
  //   (loadingExpInfo && fetchingExpInfo) ||
  //   (loadingTestInfo && fetchingTestInfo)
  // ) return <Loader />

  return (
    <div>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/users/'>
              {/* Users */}
              {t('adminPages.userSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {userFormValues.displayName}
        </h4>
        {!userFormValues.displayName ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='single-container'>
        {/* Grille principale */}
        <Grid container
          spacing={2}
          direction={{ xs: "column", md: 'row' }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* La colonne de gauche */}
          <Grid container spacing={2} direction="column" alignItems="stretch" item xs={12} md='auto'>
            <Grid item xs width={{ xs: '100%', md: 400, lg: 600 }} maxWidth={{ xs: '100%', md: 600 }}>
              <div className="flexbox-summary">
                <div className="widget">
                  <h5>{t('adminPages.userSingle.info-title')}</h5>
                  <div className="summary-content">
                    <div className="summary-items summary-titles">
                      <ul>
                        <li>{t('adminPages.userSingle.info-Email')}</li>
                        <li>{t('adminPages.userSingle.info-Profile')}</li>
                        <li>{t('adminPages.userSingle.info-Status')}</li>
                      </ul>
                    </div>
                    <div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
                      <ul>
                        <li>{userFormValues.email || <Skeleton />}</li>
                        <li>
                          {!!userFormValues.profile
                            ? <select
                              required
                              name="profile"
                              value={userFormValues.profile}
                              onChange={handleInputChange}
                            >
                              {userProfile.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            : <Skeleton />
                          }
                        </li>
                        <li>{userFormValues.status || <Skeleton />}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-middle">
                {/* La Grid des 3 boutons */}
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<RiSendPlane2Line />}
                      sx={{
                        backgroundColor: "var(--button-background-7)",
                        textTransform: 'none',
                        fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                        ':hover': { backgroundColor: "var(--button-background-hover-7)" }
                      }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      {t('button.submit')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<RiDeleteBin2Line />}
                      sx={{
                        backgroundColor: "var(--button-background-5)",
                        textTransform: 'none',
                        fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                        ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                      }}
                      onClick={(e) => { handleClickOpenDelete(e) }}
                    >
                      {t('button.delete')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<RiMessage2Line />}
                      sx={{
                        backgroundColor: "var(--button-background-4)",
                        textTransform: 'none',
                        fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                        ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                      }}
                      onClick={(e) => { handleClickOpenComment(e) }}
                    >
                      {t('button.backDraft')}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<RiThumbUpLine />}
                      sx={{
                        backgroundColor: "var(--button-background-6)",
                        textTransform: 'none',
                        fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                        ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                      }}
                      onClick={(e) => { handleClickOpenAccept(e) }}
                    >
                      {t('button.accept')}
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="flexbox-summary">
                <div className="widget">
                  <h5>{t('adminPages.userSingle.history-title')}</h5>
                </div>
                <RegisterHistory history={userFormValues.history} loadingData={(loadingUser && fetchingUser)} />
              </div>
            </Grid>
          </Grid>
          {/* La colonne de droite (qui passe en dessous sur petit écran) */}
          <Grid item xs={12} md id='alert-right-column'>
            <Grid container spacing={2} flexDirection={'column'} alignItems="stretch">
              <Grid item xs maxWidth={'100%'} width={'100%'}>
                <RegisterSummary
                  personalInfos={personalInfos}
                  expInfos={expInfos}
                  companyInfos={companyInfos}
                  testInfos={testInfos}
                  loadingData={
                    (loadingPersonalInfo && fetchingPersonalInfo) ||
                    (loadingCompanyInfo && fetchingCompanyInfo) ||
                    (loadingExpInfo && fetchingExpInfo) ||
                    (loadingTestInfo && fetchingTestInfo)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <DialogAlert
          maxWidth='sm'
          dialOpen={dialOpenAccept}
          title={t('adminPages.userSingle.dialog-accept-title')}
          content1={t('adminPages.userSingle.dialog-accept-content') + userFormValues.displayName + '?'}
          content2={t('adminPages.userSingle.dialog-accept-content2')}
          handleClose={() => handleClose()}
          handleConfirm={(e) => handleAccept(e)}
        />
        <DialogAlert
          maxWidth='sm'
          dialOpen={dialOpenDelete}
          title={t('adminPages.userSingle.dialog-delete-title')}
          content1={t('adminPages.userSingle.dialog-delete-content') + userFormValues.displayName + '?'}
          content2={t('adminPages.userSingle.dialog-delete-content2')}
          handleClose={() => handleClose()}
          handleConfirm={(e) => handleDelete(e)}
        />
        <DialogComment
          maxWidth='md'
          commentRequired
          dialOpen={dialOpenComment}
          title={t('adminPages.userSingle.dialog-comment-title')}
          handleClose={() => handleClose()}
          handleConfirm={(e, content) => handleBackToDraft(e, content)}
        />
      </div>
    </div >
  );
}

export default UserSingle;