import axios from "axios";
import { calc_nhub_api } from './envURL'
import { useAuth0 } from '@auth0/auth0-react';   

const API_URL = `${calc_nhub_api()}/public/`;
// const TTL = (3 * 24 * 60 * 60 * 1000) // 2 days TTL < 3 days TTL of JWT token

// export function login(email, password) {
//   return axios
//     .post(API_URL + "login", {
//       email,
//       password
//     },{withCredentials: true})
//     .then(response => {
//       if (response.data._id) {
//         const now = new Date();
//         const localStorageInfos = {
//           expiry: now.getTime() + TTL,
//           user: response.data
//         }
//         localStorage.setItem("user", JSON.stringify(localStorageInfos));
//       }

//       return response.data; // contains all required user infos: profile, client_id, displayName, email. 
//     }).catch(error => {
//       // don't care about the error, just retain that there was an error
//       // TODO: maybe give the infos to frontend that error can be a timeout issue?
//       return false
//     });
// }

export function logout() {
  localStorage.removeItem("user");
  useAuth0.logout();
}

// return false if no infos or expired infos
export function getCurrentUser() {
  const localStorageInfos = JSON.parse(localStorage.getItem('user'));
  const now = new Date();
  if (!localStorageInfos) {
    // no infos, no return
    return false
  }
  if (!localStorageInfos.expiry) {
    // unexpected, removing infos
    localStorage.removeItem("user");
    return false
  }
  else if (now.getTime() > localStorageInfos.expiry) {
    // expired ! removing infos
    localStorage.removeItem("user");
    return false
  }
  else {
    // allright
    return localStorageInfos.user;
  }
}

export function register({lastName, firstName, displayName, email, password, GDPR, CGU, profile}) {
  return axios
    .post(API_URL + "register", {
      lastName,
      firstName,
      displayName,
      email,
      password,
      GDPR,
      CGU,
      profile
    })
    .then(response => {
      return response.data;
    })
    .catch(error => { throw error });
}

//check if displayName or email exist
export function checkUsernames({displayName, email}) {
  return axios
    .post(API_URL + 'usernames', {
      displayName,
      email
    })
    .then((response) => {
      return response.data
    })
    .catch(error => { throw error });
}