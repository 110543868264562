import React, { useContext } from 'react'

import '../styles/InfoPages.css'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button } from '@mui/material';

import { ResponseSnackbarErrorHandler } from '../components/ResponseSnackbar';
import { submitUserMe } from '../services/user.service';
import RegisterSummary from '../components/RegisterSummary';
import { UserInfosContext } from '../context/UserInfosContext';

function SummaryPage({ personalInfos, expInfos, companyInfos, testInfos, loadingData }) {

    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const { userInfos, setSnackbarObj } = useContext(UserInfosContext);

    function switchButtonLabel(status) {
        switch (status) {
            case 'Pending': return t('summaryPage.button-validation-Pending');
            case 'InProgress': return t('summaryPage.button-validation-InProgress');
            case 'Validated': return t('summaryPage.button-validation-Validated');
            default: return t('summaryPage.button-validation-InProgress')
        }
    }

    const submitUserMeMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => submitUserMe(token)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['myUserInfo'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        submitUserMeMutation.mutate()
    }

    return (
        <div className='information-form'>
            <RegisterSummary personalInfos={personalInfos} expInfos={expInfos} companyInfos={companyInfos} testInfos={testInfos} loadingData={loadingData} />

            <Button
                variant="contained"
                disabled={!(userInfos?.status === 'InProgress')}
                sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '30px',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                }}
                onClick={(e) => { handleSubmit(e) }}
            >
                {switchButtonLabel(userInfos?.status)}
            </Button>
        </div >
    );
};
export default SummaryPage;