/*
    Everything to push / pull data from personalInfo API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_USER_URL = `${calc_nhub_api()}/api/personalInfo/`

//Get all info
export function getAllPersonalInfo(token) {
    return axios
        .get(API_USER_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get my info
export function getMyPersonalInfo(token) {
    return axios
        .get(API_USER_URL + '/me', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get info by his ID
export function getPersonalInfo(token, id) {
    return axios
        .get(API_USER_URL + id, { headers: { 'Authorization': `Bearer ${token}` } })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Edit my info
export function putMyPersonalInfo(token, content) {
    return axios
        .put(
            API_USER_URL + '/me',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Edit info by his ID
export function putPersonalInfo(token, id, content) {
    return axios
        .put(
            API_USER_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Delete info by his ID
export function deletePersonalInfo(token, id) {
    return axios
        .delete(API_USER_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });

}

//Upload a AOA
export function postMyIdentityProof(token, content) {
    return axios
        .post(
            API_USER_URL + 'proof/me',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Download my AOA
export function getMyIdentityProof(token) {
    return axios
        .get(API_USER_URL + 'proof/me', {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Download AOA by his ID
export function getIdentityProof(token, id) {
    return axios
        .get(API_USER_URL + 'proof/' + id, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}
