import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { RiFileUploadLine, RiCheckLine } from 'react-icons/ri';

import { Box, Button, CircularProgress } from '@mui/material';

import { UserInfosContext } from '../context/UserInfosContext';

export default function UploadButton(props) {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const { setSnackbarObj } = useContext(UserInfosContext);

    async function handleButtonClick(fileInput) {
        if (!loading) {
            setSuccess(false);
            setLoading(true);

            const file = fileInput.files[0];
            const formData = new FormData();
            formData.append('file', file);
            const put_token = await getAccessTokenSilently();
            const result = await props.downloadFunction(put_token, formData, { ...props.downloadParameters })
            if (result.message === 'File added successfully') {
                setSuccess(true)
                setLoading(false)
                props.setFileName(file.name)
                setSnackbarObj({
                    message: t("snackbar.saved"),
                    status: 'success',
                    timestamp: new Date().getTime()
                })
            } else {
                setSnackbarObj({
                    message: result.statusText,
                    responseCode: result.status,
                    status: 'error',
                    request : 'upload',
                    timestamp: new Date().getTime()
                })
                setLoading(false)
            }
        }
    }


    return (
        <Box sx={{ m: 1, position: 'relative', display: 'inline' }}>
            <Button
                variant="contained"
                component="label"
                title={t('button.upload')}
                aria-label={t('button.upload')}
                startIcon={success ? <RiCheckLine /> : <RiFileUploadLine />}
                sx={{
                    backgroundColor: "var(--button-background-4)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '0 -8px',
                    ':hover': { backgroundColor: "var(--button-background-hover-4)" },
                }}
                disabled={loading}
            >
                <input
                    hidden
                    accept=".pdf"
                    type="file"
                    onChange={(e) => {
                        handleButtonClick(e.target);
                    }}
                />
                {loading ? t('button.uploading') : props.label}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "var(--button-background-6)",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    )
}