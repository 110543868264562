import '../styles/LoginPage.css'

import React from 'react';

// import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// import Button from '@mui/material/Button';

import LoginButton from './LoginButton';

function RegisterConfirm() {

    const { t } = useTranslation();
    // let navigate = useNavigate();

    // const handleLogin = () => navigate('/');

    return (
        <div className='login-form'>
            <div className='register-form'>
                <div className='register-titles register-form-titles'>
                    <span className='login-form-titles'>{t('registerConfirm.title')}</span>
                    <span>{t('registerConfirm.subTitle')}</span>
                    <span>{t('registerConfirm.subTitle2')}</span>
                </div>
            </div>
            {/* <Button
                variant="contained"
                type="submit"
                onClick={() => { handleLogin() }}
                sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                }}
            >
                {t('login.button-connect')}
            </Button> */}
            <LoginButton />
        </div>

    );
};
export default RegisterConfirm;