import React from "react";

import '../styles/InfoPages.css'

import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';



function DialogAlert({maxWidth, dialOpen, handleClose, title, content1, content2, handleConfirm }) {

    const { t } = useTranslation();

    return (
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          open={dialOpen}
          onClose={() => handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
     <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
          <DialogContent>
            {content1}
            <br />
            {content2}
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => handleConfirm(e)}>{t('button.confirm')}</Button>
            <Button onClick={() => handleClose()}>{t('button.cancel')}</Button>
          </DialogActions>
        </Dialog>
    );
};
export default DialogAlert;