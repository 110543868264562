import '../styles/LicenseItem.css'

import React from 'react'
import { useTranslation } from 'react-i18next';

import { Button, Alert, Skeleton } from '@mui/material';
import { RiSendPlaneLine, RiYoutubeLine } from 'react-icons/ri';

import { dateDisplay } from '../utils/formatDate';
import { BreakLineRender } from '../utils/formatText'
import { useProgressiveImage } from '../hooks/useProgressiveImage';

export default function LicenseItem({ name, description, presentation, status, availableDate, rejectReason, image, uri, video, loadingData }) {

    const { t } = useTranslation();
    const imageLoaded = useProgressiveImage(image)


    function LicenseStatus({ status }) {
        switch (status) {
            case "Failed": return <span className='licenseItem-status failed'>{t('testInfoPage.status-failed')}</span>
            case "Failed-Retryable_NoDelay": return <span className='licenseItem-status failed'>{t('testInfoPage.status-failed')}</span>
            case "Failed-Retryable_Delay": return <span className='licenseItem-status failed'>{t('testInfoPage.status-failed')}</span>
            case "Available": return <span className='licenseItem-status not-started'>{t('testInfoPage.status-notObtained')}</span>
            case "Pending": return <span className='licenseItem-status pending'><i>{t('testInfoPage.status-pending')}</i></span>
            case "Obtained": return <span className='licenseItem-status'>{t('testInfoPage.status-obtained')}</span>
            default: return <span className='licenseItem-status not-started'>{t('testInfoPage.status-notObtained')}</span>
        }
    }

    const date_availableDate = new Date(availableDate)
    const date_currentDate = new Date()

    return (
        <div className='licenseItem-container widget'>
            <div className='licenseItem-identity'>
                <div className='licenseItem-logo'>
                    {!loadingData && imageLoaded
                        ? <img src={image} alt={name} />
                        : <Skeleton variant="rounded" width='inherit' height='inherit' />
                    }
                </div>
                <div className='licenseItem-contentCenter'>
                    <h1>{name}</h1>
                    <p>{presentation}</p>
                    <p>{description}</p>
                    <p>
                        <span className='licenseItem-sectionTitle'> {t('testInfoPage.video')} : </span>
                        <Button
                            href={video} target="_blank" rel="noopener noreferrer"
                            title={t('button.watch')} aria-label={t('button.watch')}
                            variant="contained"
                            endIcon={<RiYoutubeLine />}
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                // margin: "-4px 0 0 10px",
                                marginTop: "-4px",
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                        >
                            {t('button.watch')}
                        </Button>

                    </p>
                    <div className='licenseItem-statusDates'>
                        <span className='licenseItem-sectionTitle'>{t('testInfoPage.status')} : </span>
                        {loadingData
                            ? <Skeleton variant="rounded" width={'95px'} height={'39px'} sx={{ margin: '5px 10px' }} />
                            : <LicenseStatus status={status} />
                        }
                        {rejectReason
                            ? <Alert severity={'error'}
                                variant='outlined'
                                sx={{
                                    padding: '0px 10px',
                                    marginRight: '10px'
                                }}
                            >
                                {t('testInfoPage.reject_reason')} : <BreakLineRender content={rejectReason} />
                            </Alert>
                            : null
                        }
                    </div>
                    <div className='licenseItem-statusDates'>
                        {status?.includes('Failed-Retryable')
                            ? <>
                                <Alert severity={'info'}
                                    variant='outlined'
                                    sx={{
                                        padding: '0px 10px',
                                        marginRight: '10px'
                                    }}
                                >
                                    {t('testInfoPage.availableAt')} {(date_availableDate > date_currentDate)
                                        ? dateDisplay(availableDate)
                                        : t('test.now')
                                    }
                                </Alert>
                                <Button
                                    disabled={date_availableDate > date_currentDate}
                                    href={uri} target="_blank" rel="noopener noreferrer"
                                    title={t('testInfoPage.button-linkDojo')} aria-label={t('testInfoPage.button-linkDojo')}
                                    variant="contained"
                                    endIcon={<RiSendPlaneLine />}
                                    sx={{
                                        backgroundColor: "var(--button-background-6)",
                                        textTransform: 'none',
                                        fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                        // margin: "-4px 0 0 10px",
                                        marginTop: "-4px",
                                        ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                    }}
                                >
                                    {t('button.retryTest')}
                                </Button>
                            </>
                            : null}
                        {status === "Available"
                            ? <Button
                                href={uri}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={t('testInfoPage.button-linkDojo')}
                                aria-label={t('testInfoPage.button-linkDojo')}
                                variant="contained"
                                endIcon={<RiSendPlaneLine />}
                                sx={{
                                    backgroundColor: "var(--button-background-6)",
                                    textTransform: 'none',
                                    fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                    // margin: "-4px 0 0 10px",
                                    marginTop: "-4px",
                                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                                }}
                            >
                                {t('button.takeTest')}
                            </Button>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
