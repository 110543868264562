import '../../styles/Tables.css'

import React from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { dateDisplay, } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import SkeletonTable from '../../components/SkeletonTable';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';


function UsersTable({usersList, loadingData}) {
  const { t } = useTranslation();
  const data = usersList;

  const headers = [
    { Header: t('tables.header-Id'), width: '149px' },
    { Header: t('tables.header-DisplayName') },
    { Header: t('tables.header-LastName') },
    { Header: t('tables.header-FirstName') },
    { Header: t('tables.header-Profile') },
    { Header: t('tables.header-Status') },
    { Header: t('tables.header-CreatedDate') },
    { Header: t('tables.header-UpdateDate') },
  ]
  if (loadingData) return <SkeletonTable headers={headers} rows={20} />
  
  return <Table data={data} />
}


function Table({ data }) {
  const { t } = useTranslation();

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Id'),
        accessor: '_id', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Cell: ({ row, cell: { value } }) =>
          <Link to={{
            pathname: '/users/' + row.original._id,
            state: { value },
          }}
            values={value}>{value} </Link>
      },
      {
        Header: t('tables.header-DisplayName'),
        accessor: 'displayName',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-LastName'),
        accessor: 'lastName',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-FirstName'),
        accessor: 'firstName',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-Profile'),
        accessor: 'profile',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
        maxWidth: 20,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>
      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className='information-form'>
      <div className='tableFixHead invest-table'>
        <table {...getTableProps()} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                    <div className='th-div'>
                      {column.render('Header')}
                      <span className='sort-span'>
                        {column.canSorter
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? <FaSortDown color='var(--table-th-filter-active)' />
                              : <FaSortUp color='var(--table-th-filter-active)' />
                            : < FaSort />
                          : ''
                        }
                      </span>
                      <span>{column.canFilter ? column.render('Filter') : null}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps()} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UsersTable;