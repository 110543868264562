import '../styles/Home.css';

import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { Grid, Checkbox, Button } from '@mui/material';
import { RiUserFollowLine, RiBriefcaseLine, RiBuilding2Line, RiMedalLine } from 'react-icons/ri';


function WelcomeView({ handleClose }) {

    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);

    const handleCheck = () => { setChecked(!checked) };

    return (
        <div className='nhub-home-container'>
            <h2>{t('welcomeView.title')}</h2>
            <p>{t('welcomeView.line1')}<br />{t('welcomeView.line2')}</p>
            <p>{t('welcomeView.line3')}</p>
            <Grid container rowSpacing={2} columnSpacing={{xs:0, sm:2}} justifyContent="center" width={{ xs: '100%', md: '60vw' }} margin={"10px"}>
                <Grid item xs={12} sm={6}>
                    <div className="home-item widget">
                        <RiUserFollowLine color='var(--nhub-color-secondary-4)' />
                        <div className='home-item-content'>
                            <h5>{t('welcomeView.personalInfo-title')}</h5>
                            <p>{t('welcomeView.personalInfo-text')}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="home-item widget">
                        <RiBriefcaseLine color='var(--nhub-color-secondary-5)' />
                        <div className='home-item-content'>
                            <h5>{t('welcomeView.expInfo-title')}</h5>
                            <p>{t('welcomeView.expInfo-text')}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="home-item widget">
                        <RiBuilding2Line color='var(--nhub-color1)' />
                        <div className='home-item-content'>
                            <h5>{t('welcomeView.companyInfo-title')}</h5>
                            <p>{t('welcomeView.companyInfo-text')}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="home-item widget">
                        <RiMedalLine color='var(--nhub-color-secondary-6)' />
                        <div className='home-item-content'>
                            <h5>{t('welcomeView.testInfo-title')}</h5>
                            <p>{t('welcomeView.testInfo-text')}</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <p>{t('welcomeView.sub-content')}</p>
            <div>
                <Checkbox
                    checked={checked}
                    onChange={handleCheck}
                    sx={{
                        color: "var(--nhub-color1)",
                        '&.Mui-checked': { color: 'var(--nhub-color1)', },
                    }}
                />
                <span>{t('welcomeView.checkbox-text')}</span>
            </div>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: "var(--button-background-9)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                    minWidth: '80px',
                    marginTop: '10px',
                    ':hover': { backgroundColor: "var(--button-background-hover-9)" }
                }}
                onClick={(e) => { handleClose(e, checked) }}
            >
                {t('button.close')}
            </Button>
        </div>
    )
}

export default WelcomeView