/**
 * @description Set api URL in function of which env we are.
 * We use this block of code in order to manage the calls API.
 * @returns string
 */

export function calc_nhub_api() {
    var __nhub_api_rooturl;
    if (process.env.REACT_APP_NHUB_API) {
        // defined, let's look at
        if (process.env.REACT_APP_NHUB_API === "production") {
            __nhub_api_rooturl = "https://api.register.nybble-security.io"
        } else {
            __nhub_api_rooturl = "https://api.register-dev.nybble-security.io"
        }
    }
    else {
        // assuming dev     
        __nhub_api_rooturl = "https://api.register-dev.nybble-security.io"
    }
    return __nhub_api_rooturl;
}