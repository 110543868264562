import nybbleLogo from '../assets/nybble-logo/Nybble-Logo-White.svg'
import '../styles/History.css'

import React from 'react';
import { useTranslation } from "react-i18next";

import { Grid } from '@mui/material';

import { GetRelativeTime, getInitials } from '../utils/formatDate'
import { BreakLineRender } from '../utils/formatText'
import SkeletonHistory from '../components/SkeletonHistory';


function RegisterHistory({history, loadingData}) {
    const { t } = useTranslation();

    if (loadingData) return <SkeletonHistory rows={4} />

    if (!history || history.length === 0) {
        // render nothing
        return <p>{t('adminPages.userSingle.history-info-noData')}</p>
    }
    else {
        return (
            <div>
                <div className="history-container">
                    {history.slice(0).reverse().map((element, i) => (
                        <div key={i} className='history-box widget'>
                            <Grid item container spacing={2} xs={12}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item container xs={1.5}>
                                    {(element.profile === 'Admin' || element.profile === 'Robot')
                                        ? (
                                            <div className='avatar'>
                                                <img src={nybbleLogo} alt='nybbleLogo' />
                                            </div>
                                        ) : (
                                            <div className='avatar'>
                                                {getInitials(element.author)}
                                            </div>
                                        )}
                                </Grid>
                                <Grid item container xs={8} spacing={0.5} justifyContent={'flex-start'} >
                                    <Grid item container xs={12} justifyContent={'flex-start'}>
                                        <span className='history-user'>
                                            {element.author}
                                        </span>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent={'flex-start'}>
                                        <span className='history-subject'>
                                            {element.subject === "registration" ? t('history.subject.registration')
                                                : element.subject === "backToDraft" ? t('history.subject.backToDraft')
                                                    : element.subject === "updated" ? t('history.subject.updated')
                                                        : element.subject === "fileUploaded" ? t('history.subject.fileUploaded')
                                                            : element.subject}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={2.5} justifyContent={'flex-end'} >
                                    <span className='history-date'>
                                        {GetRelativeTime(element.timestamp, t('lang'))}
                                    </span>
                                </Grid>
                                <Grid item container xs={12} justifyContent={'flex-start'}>
                                    <span className='history-description'>
                                        {element.content ? <BreakLineRender content={element.content} /> : null}
                                    </span>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default RegisterHistory