import React, { useState } from 'react'

import '../styles/InfoPages.css'

import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';



function DialogComment({ maxWidth, dialOpen, handleClose, title, handleConfirm, commentRequired }) {

  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    comment: ''
  });

  // update the react state with user input
  function handleInputChange(e) {
    const { value } = e.target
    setFormValues({ comment: value })
  }

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={dialOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          rows={3}
          name="comment"
          variant="standard"
          onChange={handleInputChange}
          value={formValues.comment}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!formValues.comment && commentRequired} onClick={(e) => handleConfirm(e,formValues)}>{t('button.confirm')}</Button>
        <Button onClick={() => handleClose()}>{t('button.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogComment;