import './App.css';

import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Error404 from './components/Error404'
import ConfirmEmail from './components/ConfirmEmail'
import Header from './components/Header'
import BurgerNavBar from './components/BurgerNavBar';
import LoginPage from './pages/LoginPage';
import PersonalInfoPage from './pages/PersonalInfoPage';
import ExpInfoPage from './pages/ExpInfoPage';
import CompanyInfoPage from './pages/CompanyInfoPage';
import TestPage from './pages/TestPage';
import SummaryPage from './pages/SummaryPage';
import UsersTable from './pages/Admin/UsersTable';
import UserSingle from './pages/Admin/UserSingle';
import RegisterHistory from './components/RegisterHistory';
import WelcomeView from './components/WelcomeView';

import Loader from './components/Loader'
import { ResponseSnackbar, ResponseSnackbarErrorHandler } from './components/ResponseSnackbar';
import watermark from './assets/nybble-logo/Nybble-Logo-Full-Color.svg'

import { RiCheckLine } from 'react-icons/ri';

import i18n from "./i18n";
import { Language } from './data/i18n/enums/Language.ts';
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

// import { getCurrentUser } from './context/authentication.service';
import { getAllUsers } from './services/user.service';
import { getMyPersonalInfo } from './services/personalInfo.service';
import { getMyExpInfo } from './services/expInfo.service';
import { getMyCompanyInfo } from './services/companyInfo.service';
import { getMyTestInfo } from './services/testInfo.service';
import { getUserMe, putUserMe } from './services/user.service';
import { UserInfosContext } from './context/UserInfosContext';


function App() {

  const { t } = useTranslation();
  const { isAuthenticated, getAccessTokenSilently, user, isLoading } = useAuth0();
  const queryClient = useQueryClient()

  const [userInfos, setUserInfos] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [pendingUsersList, setPendingUsersList] = useState([]);

  const [personalIsChecked, setPersonalIsChecked] = useState(false);
  const [expIsChecked, setExpIsChecked] = useState(false);
  const [companyIsChecked, setCompanyIsChecked] = useState(false);
  const [testIsChecked, setTestIsChecked] = useState(false);

  const [snackbarObj, setSnackbarObj] = useState();

  const [dialOpen, setDialOpen] = useState(false);
  const localStorageLanguageInfo = JSON.parse(localStorage.getItem('handleLanguage'));

  const {
    data: myUserInfos,
    isLoading: loadingMyUserInfos,
    error: errorMyUserInfos,
    failureReason: failureMyUserInfos,
    failureCount: failureCountMyUserInfos,
    isFetching: fetchingMyUserInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!user?.email_verified, // ensures the query is only executed when these conditions are met.
    queryKey: ['myUserInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getUserMe(token)),
  });

  useEffect(() => {
    if (myUserInfos) {
      setUserInfos(myUserInfos)
    }
  }, [myUserInfos])

  const isUser = userInfos?._id && (userInfos?.profile === 'Analyst_L1' || userInfos?.profile === 'Hunter' || userInfos?.profile === 'Hunter-Analyst')

  const {
    data: myPersonalInfos,
    isLoading: loadingMyPersonalInfos,
    error: errorMyPersonalInfos,
    failureReason: failureMyPersonalInfos,
    failureCount: failureCountMyPersonalInfos,
    isFetching: fetchingMyPersonalInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!isUser, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myPersonalInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyPersonalInfo(token)),
  });

  const {
    data: myCompanyInfos,
    isLoading: loadingMyCompanyInfos,
    error: errorMyCompanyInfos,
    failureReason: failureMyCompanyInfos,
    failureCount: failureCountMyCompanyInfos,
    isFetching: fetchingMyCompanyInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!isUser, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myCompanyInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyCompanyInfo(token)),
  });

  const {
    data: myExpInfos,
    isLoading: loadingMyExpInfos,
    error: errorMyExpInfos,
    failureReason: failureMyExpInfos,
    failureCount: failureCountMyExpInfos,
    isFetching: fetchingMyExpInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!isUser, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myExpInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyExpInfo(token)),
  });

  const {
    data: myTestInfos,
    isLoading: loadingMyTestInfos,
    error: errorMyTestInfos,
    failureReason: failureMyTestInfos,
    failureCount: failureCountMyTestInfos,
    isFetching: fetchingMyTestInfos
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!isUser, // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['myTestInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getMyTestInfo(token)),
  });

  const {
    data: usersList,
    isLoading: loadingUsersList,
    error: errorUsersList,
    failureReason: failureUsersList,
    failureCount: failureCountUsersList,
    isFetching: fetchingUsersList
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Admin', // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['usersList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllUsers(token)),
  });

  const putUserMeMutation = useMutation({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    mutationFn: (content) => getAccessTokenSilently().then((token) => putUserMe(token, content)),
    onSuccess: (data) => {
      queryClient.setQueryData(['myUserInfo'], data) //update query data 
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })


  const handleClickOpen = () => { setDialOpen(true); };
  const handleClose = () => { setDialOpen(false) };
  const handleCloseWelcomeView = (e, checked) => {
    if (checked) {
      e.preventDefault()
      putUserMeMutation.mutate({ welcomeView: false })
    }
    setUserInfos({ ...userInfos, welcomeView: false })
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.email_verified === false) {
        setEmailVerified(false)
      }
    }
  }, [isAuthenticated, user?.email_verified])

  useEffect(() => {
    if (usersList) {
      setPendingUsersList(usersList.filter(user => user.status === "Pending"))
    }
  }, [usersList])

  useEffect(() => {
    if (isUser && (myPersonalInfos?.civility && myPersonalInfos?.lastName && myPersonalInfos?.firstName && myPersonalInfos?.birthDate && myPersonalInfos?.nationality && myPersonalInfos?.phoneNumber && myPersonalInfos?.address && myPersonalInfos?.city && myPersonalInfos?.postalCode && myPersonalInfos?.country && myPersonalInfos?.identity_proof)) {
      setPersonalIsChecked(true)
    }
  }, [isUser, myPersonalInfos])

  useEffect(() => {
    if (isUser && (myExpInfos?.lastPosition && myExpInfos.yearsExp && myExpInfos.diploma && (myExpInfos.linkedin || myExpInfos.resume))) {
      setExpIsChecked(true)
    }
  }, [isUser, myExpInfos,])

  useEffect(() => {
    if (isUser && (myCompanyInfos?.identification && myCompanyInfos.number && myCompanyInfos.finance_iban && myCompanyInfos.finance_bic && myCompanyInfos.address && myCompanyInfos.city && myCompanyInfos.postalCode && myCompanyInfos.country)) {
      setCompanyIsChecked(true)
    }
  }, [isUser, myCompanyInfos])

  useEffect(() => {
    if (isUser && (myTestInfos?.analystTest?.status === "Obtained" || myTestInfos?.hunterTest?.status === "Obtained")) {
      setTestIsChecked(true)
    }
  }, [isUser, myTestInfos])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      /* if connected ? */
      if (userInfos?.language !== 'Browser') {
        if (userInfos?.language === 'fr') {
          i18n.changeLanguage(Language.FR);
        } else if (userInfos?.language === 'en') {
          i18n.changeLanguage(Language.EN);
        }
      } else if (localStorageLanguageInfo) {
        if (localStorageLanguageInfo === "fr") {
          i18n.changeLanguage(Language.FR);
        } else if (localStorageLanguageInfo === "en") {
          i18n.changeLanguage(Language.EN);
        }
      }
    }
    return () => mounted = false
  }, [localStorageLanguageInfo, userInfos])

  useEffect(() => {

    const snackbarPersonalInfos = ResponseSnackbarErrorHandler('myPersonalInfo', errorMyPersonalInfos, failureMyPersonalInfos, failureCountMyPersonalInfos)
    if (snackbarPersonalInfos) { setSnackbarObj(snackbarPersonalInfos) }

    const snackbarExpInfos = ResponseSnackbarErrorHandler('myExpInfo', errorMyExpInfos, failureMyExpInfos, failureCountMyExpInfos)
    if (snackbarExpInfos) { setSnackbarObj(snackbarExpInfos) }

    const snackbarCompanyInfos = ResponseSnackbarErrorHandler('myCompanyInfo', errorMyCompanyInfos, failureMyCompanyInfos, failureCountMyCompanyInfos)
    if (snackbarCompanyInfos) { setSnackbarObj(snackbarCompanyInfos) }

    const snackbarTestInfos = ResponseSnackbarErrorHandler('myTestInfo', errorMyTestInfos, failureMyTestInfos, failureCountMyTestInfos)
    if (snackbarTestInfos) { setSnackbarObj(snackbarTestInfos) }

    const snackbarUserInfos = ResponseSnackbarErrorHandler('myUserInfo', errorMyUserInfos, failureMyUserInfos, failureCountMyUserInfos)
    if (snackbarUserInfos) { setSnackbarObj(snackbarUserInfos) }

    const snackbarUsersList = ResponseSnackbarErrorHandler('usersList', errorUsersList, failureUsersList, failureCountUsersList)
    if (snackbarUsersList) { setSnackbarObj(snackbarUsersList) }

  }, [errorMyCompanyInfos, errorMyExpInfos, errorMyPersonalInfos, errorMyTestInfos, errorMyUserInfos, errorUsersList, failureCountMyCompanyInfos, failureCountMyExpInfos, failureCountMyPersonalInfos, failureCountMyTestInfos, failureCountMyUserInfos, failureCountUsersList, failureMyCompanyInfos, failureMyExpInfos, failureMyPersonalInfos, failureMyTestInfos, failureMyUserInfos, failureUsersList]);


  /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if (isLoading ||
    (loadingMyUserInfos && fetchingMyUserInfos)
    // ||
    // (loadingMyPersonalInfos && fetchingMyPersonalInfos) ||
    // (loadingMyCompanyInfos && fetchingMyCompanyInfos) ||
    // (loadingMyExpInfos && fetchingMyExpInfos) ||
    // (loadingMyTestInfos && fetchingMyTestInfos) 
    // ||
    // (loadingUsersList && fetchingUsersList)
  ) { return <Loader />; }

  if (!isAuthenticated) {
    return <LoginPage />
  }

  if (isAuthenticated && !emailVerified) {
    return <ConfirmEmail />
  }

  return (
    !userInfos
      ? (<Loader />)
      : (
        <UserInfosContext.Provider value={{
          userInfos,
          setUserInfos,
          snackbarObj, setSnackbarObj
        }}>
          <div className="App">
            <Header handleClickOpen={handleClickOpen} />
            <div id="mainContent">
              <BurgerNavBar summaryAvailable={personalIsChecked && expIsChecked && companyIsChecked && testIsChecked} profile={userInfos.profile} />
              {userInfos?.welcomeView
                ? <WelcomeView handleClose={handleCloseWelcomeView} />
                : <>

                  {userInfos?.profile === 'Admin'
                    ? <>
                      {/* <div className="menu-title"> */}
                      <div className="menu-tab-bar">
                        <ul className='menu-tab-items'>
                          <li><NavLink activeclassname={"active"} to='/users'>{t('appBarNav.users')}</NavLink></li>
                          <li><NavLink activeclassname={"active"} to='/pending'>{t('appBarNav.pendingUsers')} {pendingUsersList ? '(' + pendingUsersList.length + ')' : null}</NavLink></li>
                        </ul>
                      </div>
                      {/* <div className='routes-container'> */}
                      <Routes>
                        <Route path="/users" element={<UsersTable usersList={usersList} loadingData={(loadingUsersList && fetchingUsersList)}/>} />
                        <Route path="/pending" element={<UsersTable usersList={pendingUsersList} loadingData={(loadingUsersList && fetchingUsersList)}/>} />
                        <Route path="/users/:userId" element={<UserSingle />} />

                        <Route path="*" element={<Navigate to="/users" replace />} />
                      </Routes>
                    </>
                    : (userInfos.profile === 'Hunter-Analyst')
                      ? <>
                        {/* <div className="menu-title"> */}
                        <div className="menu-tab-bar">
                          <ul className='menu-tab-items'>
                            <li><NavLink activeclassname={"active"} to='/personal'>{t('appBarNav.personalInfo')}</NavLink>{personalIsChecked ? <RiCheckLine /> : null}</li>
                            <li><NavLink activeclassname={"active"} to={'/experience'}>{t('appBarNav.expInfo')}</NavLink>{expIsChecked ? <RiCheckLine /> : null}</li>
                            <li><NavLink activeclassname={"active"} to={'/company'}>{t('appBarNav.companyInfo')} </NavLink>{companyIsChecked ? <RiCheckLine /> : null}</li>
                            <li><NavLink activeclassname={"active"} to={'/test'}>{t('appBarNav.test')}</NavLink>{testIsChecked ? <RiCheckLine /> : null}</li>
                            {personalIsChecked && expIsChecked && companyIsChecked && testIsChecked
                              ? <li><NavLink activeclassname={"active"} to={'/summary'} >{t('appBarNav.summary')}</NavLink></li>
                              : <li><NavLink className={"inactive"} >{t('appBarNav.summary')}</NavLink></li>
                            }
                          </ul>
                        </div>
                        {/* <div className='routes-container'> */}
                        <Routes>
                          <Route path="/personal" element={<PersonalInfoPage personalInfos={myPersonalInfos} loadingData={(loadingMyPersonalInfos && fetchingMyPersonalInfos)} />} />
                          <Route path="/experience" element={<ExpInfoPage expInfos={myExpInfos} loadingData={(loadingMyExpInfos && fetchingMyExpInfos)} />} />
                          <Route path="/company" element={<CompanyInfoPage companyInfos={myCompanyInfos} personalInfos={myPersonalInfos} loadingData={(loadingMyCompanyInfos && fetchingMyCompanyInfos) || (loadingMyPersonalInfos && fetchingMyPersonalInfos)} />} />
                          <Route path="/test" element={<TestPage testInfos={myTestInfos} loadingData={(loadingMyTestInfos && fetchingMyTestInfos)} />} />
                          {personalIsChecked && expIsChecked && companyIsChecked && testIsChecked
                            ?
                            <Route path="/summary" element={<SummaryPage
                              personalInfos={myPersonalInfos}
                              expInfos={myExpInfos}
                              companyInfos={myCompanyInfos}
                              testInfos={myTestInfos}
                              loadingData={
                                (loadingMyPersonalInfos && fetchingMyPersonalInfos) ||
                                (loadingMyCompanyInfos && fetchingMyCompanyInfos) ||
                                (loadingMyExpInfos && fetchingMyExpInfos) ||
                                (loadingMyTestInfos && fetchingMyTestInfos)}
                            />} />
                            : null
                          }

                          <Route path="*" element={<Navigate to="personal" replace />} />
                        </Routes>
                      </>
                      : <>
                        <Routes>
                          <Route path="*" element={<Error404 />} />
                        </Routes>
                      </>
                  }
                </>
              }
              <Dialog
                fullWidth
                maxWidth='md'
                open={dialOpen}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t('header.label-History')}
                </DialogTitle>
                <DialogContent>
                  <RegisterHistory history={userInfos.history} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClose()}>{t('button.close')}</Button>
                </DialogActions>
              </Dialog>
            </div>
            <img src={watermark} alt='watermark' className='watermark' />
            {snackbarObj?.message
              ? <ResponseSnackbar
                status={snackbarObj.status}
                message={snackbarObj.message}
                responseCode={snackbarObj.responseCode}
                timestamp={snackbarObj.timestamp}
                request={snackbarObj.request}
                failureCount={snackbarObj.failureCount}
              />
              : null}
          </div >
        </UserInfosContext.Provider>
      )
  );
};
export default App;
