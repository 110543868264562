import React, { useState } from "react";

import { priorityTranslator } from "../utils/formatDate";

import {TextField, Select, MenuItem} from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import { RiFilter2Line } from "react-icons/ri";

// export function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length
//   const [value, setValue] = useState(globalFilter)
//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value || undefined)
//   }, 200)

//   return (
//     <span>
//       Search:{' '}
//       <input
//         value={value || ""}
//         onChange={e => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: '1.1rem',
//           border: '0',
//         }}
//       />
//     </span>
//   )
// }

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <TextField
              value={filterValue || ""}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                color: "var(--table-th-text",
              }}
              variant="standard"
              placeholder={`Search ${count} records...`}
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  // Render a multi-select box
  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <Select
              MenuProps={{
                disablePortal: true
              }}
              value={filterValue}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined);
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                backgroundColor: "var(--nhub-color-background-1)",
              }}
              variant="outlined"
              autoWidth
            >
              <MenuItem value="" sx={{ fontSize: 12 }}>
                <em>All</em>
              </MenuItem>
              {options.map((option, i) => (
                <MenuItem key={i} value={option} sx={{ fontSize: 12 }} >
                  <em>{option}</em>
                </MenuItem>
              ))}
            </Select>
          </div>
        </ClickAwayListener>

      ) : null}

    </>
  );
}

// This is a custom filter UI for selecting priority by number
// and render a priority name instead
export function SelectPriorityColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  // Render a multi-select box0
  return (
    <>
      <span
        className="filter-opener"
      >
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isFiltered ? (
            <RiFilter2Line style={{ color: "var(--table-th-filter-active)" }} />
          ) : (
            <RiFilter2Line />
          )}
        </IconButton>
      </span>
      {isOpen ? (
        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
          <div className="filter-container">
            <Select
              MenuProps={{
                disablePortal: true
              }}
              value={filterValue}
              onChange={(e) => {
                setIsFiltered(e.target.value);
                setFilter(e.target.value || undefined);
              }}
              sx={{
                m: 1,
                minWidth: 120,
                fontSize: 12,
                height: 24,
                maxHeight: 24,
                minHeight: 24,
                backgroundColor: "var(--nhub-color-background-1)",
              }}
              variant="outlined"
              autoWidth
            >
              <MenuItem value={undefined} sx={{ fontSize: 12 }}>
                <em>All</em>
              </MenuItem>
              {options.map((option, i) => (
                <MenuItem key={i} value={option} sx={{ fontSize: 12 }}>
                  <em>{priorityTranslator(option)}</em>
                </MenuItem>
              ))}
            </Select>
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

// // This is a custom filter UI that uses a
// // slider to set the filter value between a column's
// // min and max values
// export function SliderColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) {
//   // Calculate the min and max
//   // using the preFilteredRows

//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min)
//       max = Math.max(row.values[id], max)
//     })
//     return [min, max]
//   }, [id, preFilteredRows])

//   return (
//     <>
//       <input
//         type="range"
//         min={min}
//         max={max}
//         value={filterValue || min}
//         onChange={e => {
//           setFilter(parseInt(e.target.value, 10))
//         }}
//       />
//       <button onClick={() => setFilter(undefined)}>Off</button>
//     </>
//   )
// }

// // This is a custom UI for our 'between' or number range
// // filter. It uses two number boxes and filters rows to
// // ones that have values between the two
// export function NumberRangeColumnFilter({
//   column: { filterValue = [], preFilteredRows, setFilter, id },
// }) {
//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min)
//       max = Math.max(row.values[id], max)
//     })
//     return [min, max]
//   }, [id, preFilteredRows])

//   return (
//     <div
//       style={{
//         display: 'flex',
//       }}
//     >
//       <input
//         value={filterValue[0] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
//         }}
//         placeholder={`Min (${min})`}
//         style={{
//           width: '70px',
//           marginRight: '0.5rem',
//         }}
//       />
//       to
//       <input
//         value={filterValue[1] || ''}
//         type="number"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
//         }}
//         placeholder={`Max (${max})`}
//         style={{
//           width: '70px',
//           marginLeft: '0.5rem',
//         }}
//       />
//     </div>
//   )
// }

// // This is a custom UI for our 'between' or date range
// // filter. It uses two dates boxes and filters rows to
// // ones that have values between the two
// export function DateRangeColumnFilter({
//   column: { filterValue = [], preFilteredRows, setFilter, id },
// }) {
//   const [min, max] = React.useMemo(() => {
//     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
//     preFilteredRows.forEach(row => {
//       min = Math.min(row.values[id], min)
//       max = Math.max(row.values[id], max)
//     })
//     return [min, max]
//   }, [id, preFilteredRows])

//   return (
//     <div
//       style={{
//         display: 'flex',
//       }}
//     >
//       <input
//         value={filterValue[0] || ''}
//         type="date"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
//         }}
//         placeholder={`Min (${min})`}
//         style={{
//           width: '110px',
//           marginRight: '0.5rem',
//         }}
//       />
//       to
//       <input
//         value={filterValue[1] || ''}
//         type="date"
//         onChange={e => {
//           const val = e.target.value
//           setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
//         }}
//         placeholder={`Max (${max})`}
//         style={{
//           width: '110px',
//           marginLeft: '0.5rem',
//         }}
//       />
//     </div>
//   )
// }
