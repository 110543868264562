/*
    Everything to push / pull data from expInfo API
*/


import axios from "axios";
// import { handleAxiosErrors } from "../context/errors";
import { calc_nhub_api } from '../context/envURL'

const API_USER_URL = `${calc_nhub_api()}/api/expInfo/`

//Get all info
export function getAllExpInfo(token) {
    return axios
        .get(API_USER_URL, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get my info
export function getMyExpInfo(token) {
    return axios
        .get(API_USER_URL + 'me', {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Get info by his ID
export function getExpInfo(token, id) {
    return axios
        .get(API_USER_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Edit my info
export function putMyExpInfo(token, content) {
    return axios
        .put(
            API_USER_URL + 'me',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Edit info by his ID
export function putExpInfo(token, id, content) {
    return axios
        .put(
            API_USER_URL + id,
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Delete info by his ID
export function deleteExpInfo(token, id) {
    return axios
        .delete(API_USER_URL + id, {
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Upload a resume
export function postMyResume(token, content) {
    return axios
        .post(
            API_USER_URL + 'resume/me',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Download my Resume
export function getMyResume(token) {
    return axios
        .get(API_USER_URL + 'resume/me', {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

//Download Resume by his ID
export function getResume(token, id) {
    return axios
        .get(API_USER_URL + 'resume/' + id, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 5000
        })
        .then((response) => {
            return response.data
        })
        .catch(error => { throw error });
}

// //Upload a Diploma
// export function postMyDiploma(content) {
//     return axios
//         .post(
//             API_USER_URL + '/diploma/me',
//             content,
//             { withCredentials: true }
//         )
//         .then(response => {
//             return response.data;
//         })
//         .catch(error => handleAxiosErrors(error));
// }

// //Download my Diploma
// export function getMyDiploma() {
//     return axios
//         .get(API_USER_URL + '/diploma/me', {responseType: 'blob', withCredentials: true })
//         .then((response) => {
//             return response.data
//         })
//         .catch(error => handleAxiosErrors(error, []));
// }

// //Download Diploma by his ID
// export function getDiploma(id) {
//     return axios
//         .get(API_USER_URL + '/diploma/' + id, {responseType: 'blob', withCredentials: true })
//         .then((response) => {
//             return response.data
//         })
//         .catch(error => handleAxiosErrors(error, []));
// }