import { useState, useContext } from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { Box, Button, CircularProgress } from '@mui/material';

import { RiFileDownloadLine } from 'react-icons/ri';

import { UserInfosContext } from '../context/UserInfosContext';

export default function DownloadButton(props) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const { setSnackbarObj } = useContext(UserInfosContext);

    const handleButtonClick = async() => {
        if (!loading) {
            setLoading(true);
            const put_token = await getAccessTokenSilently();
            // download
            props.downloadFunction(put_token, props.downloadParameters)
                // .then((response) => response.blob())
                .then((blob) => {
                    // download finished
                    // 2. Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', props.fileName);
                    link.hidden = true;
                    // 3. Append to html page
                    document.body.appendChild(link);
                    // 4. Force download
                    link.click();
                    // 5. Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);

                    setSnackbarObj({
                        message: error.statusText,
                        responseCode: error.status,
                        status: 'error',
                        request : 'download',
                        timestamp: new Date().getTime()
                    })
                });
        }
    };

    return (
        <Box sx={{ m: 1, position: 'relative', display: 'inline' }}>
            <Button
                variant="text"
                title={t('button.download')}
                aria-label={t('button.download')}
                startIcon={<RiFileDownloadLine />}
                sx={{
                    // backgroundColor: "var(--button-background-6)",
                    color: "var( --content-text)",
                    textTransform: 'none',
                    fontSize: '14px', fontWeight: '400', padding: '6px 0px',
                    // ':hover': { backgroundColor: "var(--button-background-hover-6)" },
                    // ':hover': { textDecoration: 'underline 1px dashed' },
                    // .external-links a:hover{text-decoration: underline 1px dashed;}
                }}
                disabled={loading}
                onClick={handleButtonClick}
            >
                {loading ? t('button.downloading') : props.label}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "var(--button-background-6)",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    )
}