import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

import { useTranslation } from "react-i18next";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => loginWithRedirect()}
      sx={{     
        color: 'white',
        backgroundColor: "var(--button-background-8)",
        textTransform: 'none',
        fontSize: '14px', fontWeight: '400', padding: '6px 10px',
        borderRadius :'15px',
        ':hover': { backgroundColor: "var(--button-background-hover-8)" }
      }}
    >
      {t('login.button-connect')}
    </Button>
  );
};

export default LoginButton;