import React from 'react';

// import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import '../styles/BurgerNavBar.css'

import { useTranslation } from "react-i18next";


function BurgerNavBar(props) {

  const { t } = useTranslation();
  // let navigate = useNavigate();

  // const handleLogin = () => navigate('/');

  return (
    <>
      <div className="navbar">
        <div className="container nav-container">
          <input className="checkbox" type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          {props.profile === 'Admin'
            ? <div className="menu-items">
              <li><NavLink activeclassname={"active"} to='/users'>{t('appBarNav.users')}</NavLink></li>
            </div>
            : <div className="menu-items">
              <li><NavLink activeclassname={"active"} to='/personal'>{t('appBarNav.personalInfo')}</NavLink></li>
              <li><NavLink activeclassname={"active"} to={'/experience'}>{t('appBarNav.expInfo')}</NavLink></li>
              <li><NavLink activeclassname={"active"} to={'/company'}>{t('appBarNav.companyInfo')} </NavLink></li>
              <li><NavLink activeclassname={"active"} to={'/test'}>{t('appBarNav.test')}</NavLink></li>
              {props.summaryAvailable
                ? <li><NavLink activeclassname={"active"} to={'/summary'} >{t('appBarNav.summary')}</NavLink></li>
                : <li><NavLink className={"inactive"} >{t('appBarNav.summary')}</NavLink></li>
              }
            </div>
          }

          <div className="opacDiv"></div>
        </div>

      </div>


    </>
  );
};
export default BurgerNavBar;