import { Skeleton } from '@mui/material';

function SkeletonTable({ headers, rows }) {

  return (
    <div className='information-form'>
      <div className='tableFixHead invest-table'>
        <table >
          <thead>
            <tr>
              {headers.map(column => (
                <th key={column.id} >
                  <div className='th-div'>
                    {column.Header}
                    <span className="filter-opener"></span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(rows), (e, i) => {
              return <tr>
                {headers.map(column => (
                  <td key={column.id} style={{ width: column.width }} >
                    <Skeleton animation="wave" />
                  </td>
                ))}
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SkeletonTable;