import React from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@mui/material';

import '../styles/LoginPage.css'
import loginPageImage from '../assets/images/loginPageImage.webp'

import LoginBanner from './LoginBanner'

function ConfirmEmail() {

    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    return (
        <div className='login-page' style={{ backgroundImage: `url(${loginPageImage})` }}>
            <LoginBanner />
            <div className='login-content'>
                <div className='login-form'>
                    <div className='register-form'>
                        <span className='login-form-titles'>{t('confirmEmail.title')}</span>
                        <p>{t('confirmEmail.subtitle')}</p>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                                width: '80px',
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                            onClick={() => { loginWithRedirect() }}
                        >
                            {t('confirmEmail.button')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConfirmEmail